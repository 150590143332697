import { Link } from "react-router-dom";
import TextBox from "../components/cst/TextField";
import Button from "../components/cst/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { useAppDispatch } from "../redux/store";
import { loginApi } from "../redux/features/loginSlice";
import { userClaims } from "../redux/features/userClaimsSlice";
import { UserModel } from "../types/components/userModel";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import { userInfoInterface } from "../types/pages/userInfoType";
import { encrypt } from "../utilities";
import { getPermissionsFromToken } from "../utilities/getPermissionsFromToken";
import { useAuthSetting } from "../redux/UserSlice/setAuthHook";
import { useLoggedInUserInformation } from "../redux/UserSlice/setUserInfoHook";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidUserPassword, setInvalidUserPassword] = useState(false);
  const [enableLoginButton, setEnableLoginButton] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [isLogin, setLoginFlag] = useState(false);
  const loginDispatch = useAppDispatch();
  const {setUserInformation} =  useLoggedInUserInformation();
  const {setAuth} =  useAuthSetting();
  const userModel: UserModel = {
    email: email,
    password: password,
  };
  useEffect(() => {
    if (email.length !== 0 && password.length !== 0 && isVerified) {
      setEnableLoginButton(false);
    } else {
      setEnableLoginButton(true);
    }
  }, [email, password, isVerified]);

  const handleLogin = async () => {
        // Encrypt credentials
        userModel.email = encrypt(userModel.email);
        userModel.password = encrypt(userModel.password);
    setLoginFlag(true);
    loginDispatch(loginApi(userModel)).then((loginResponse) => {
      if (!loginResponse.payload.status) {
        setLoginErrorMsg(loginResponse.payload.message);
        setInvalidUserPassword(true);
        setLoginFlag(false);
      } else {
        const token = loginResponse.payload.result["accessToken"];
        localStorage.setItem("token", token);
        localStorage.setItem('loginTime', Date.now().toString());
        getPermissionsFromToken();
        loginDispatch(userClaims(token))
          .then((claimResponse) => {
            localStorage.setItem(
              "userInfo",
              JSON.stringify(claimResponse.payload.result)
            );
            setUserInformation(claimResponse.payload.result);
            const userInfoString = JSON.parse(
              localStorage.getItem("userInfo") ?? ""
            ) as userInfoInterface;
            localStorage.setItem("tenantId", userInfoString.TenantId);
          })
          .finally(() => {
            sessionStorage.setItem("isAuthenticated", "true");
            setAuth(1);
            setInvalidUserPassword(false);
            setLoginFlag(false);
          });
      }
    });
  };
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleOnChange = () => {
    setIsVerified(true);
  };
  return (
    <div className="login-waper">
      <div className="middle-ofwindow">
        <div className="login-logo">
          <img
            src={`${process.env.PUBLIC_URL}/riverside-company-logo-login.svg`}
            alt="Logo"
          />
        </div>
        <div className="login-container">
          {isLogin && (
            <Box className="login-loader">
              <CircularProgress />
            </Box>
          )}
          <h1>Log in</h1>
          <p>Please enter your login information</p>
          <div className="login-form">
            {invalidUserPassword && (
              <Alert
                severity="error"
                sx={{ width: "100%", marginBottom: "20px" }}
              >
                {loginErrorMsg}
              </Alert>
            )}
            <TextBox
              className="loginemail"
              id="email-id"
              name=""
              label="Email id"
              variant="outlined"
              size="small"
              required={true}
              onChange={handleEmail}
              maxLength={320}
            />

            <TextBox
              className="loginpassword"
              name=""
              id="password"
              label="Password"
              type="password"
              variant="outlined"
              autoComplete="current-password"
              size="small"
              required={true}
              onChange={handlePassword}
            />
            { <form>
              <ReCAPTCHA
                className="g-recaptcha recaptcha"
                sitekey="6LcSn_gpAAAAAPhPdRnKgyy5JfbXaxhwU5PKKr6p"
                onChange={handleOnChange}
              />
            </form> }
            <Button
              className="saveButton"
              type="contained"
              text="Login"
              onClick={handleLogin}
              disabled={enableLoginButton}
            />
            <div className="forget-password-link">
              <Link to={"/forgot-password"}>Forgot your password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
